/** General **/
:root{
    --gg-secondary: #FFDF3C;
    --gg-primary: #006CB5; 
    --gg-background: var(--gg-secondary);
    --gg-white: #ffffff;
    --gg-blue: var(--gg-primary);
}

.alert-gg-dimmed {
    background: #248bd0;
}

@media screen and (max-width: 672px){
    .alert-gg-dimmed{
        max-height: 75px;
    }

    .alert-gg-dimmed span .text-white {
        font-size: 12px;
    }
}

.pfp{
    max-height: 350px;
    max-width: 350px;
}

.btn-secondary {
    background-color: #FFDF3C;
}

html{
    min-height: 100%;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}


.hamburger-icon{
    font-size: 40px;
}

.gg-bg-white{
    background-color: var(--gg-white);
}

.gg-bg{
    background-color: var(--gg-background);
}

.gg-bg-blue{
    color: white;
    background-color: var(--gg-blue);
}
.gg-bg-blue:hover{
    color: white;
    background-color: var(--gg-blue);
    opacity: .8;
}

.text-gg-blue{
    color: var(--gg-blue);
    /* margin-top: 2em !important;  */
}

.text-gg-black{ 
    color: rgba(0, 0, 0, 0.815);
}

.text-gg-yellow{
    color: var(--gg-background);
}

.main-body {
    min-height: 1080px !important;
}

.my-bg{
    background-color: var(--logo-color);
}

.navbar-brand-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 85px;
    height: auto;
    filter: brightness(0) invert(1);
}

.card-footer {
    border: none;
}

.form-control {
    letter-spacing: normal;
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.sign-in-bkg, .sign-up-bkg {
    background-size: cover;
    background: #FEDF3D;
}

.sign-in {
    border-radius: 12px;
    background:  var(--gg-white);
}

.sign-in > h1 {
    color: var(--gg-primary);
    font-size: 32px;
}

.sign-in a, .sign-up a {
    color: #006DB5;
}

.sign-in-btn {
    background: var(--gg-primary);
    color:  var(--gg-white);
}

.sign-in-btn:hover {
    background:  var(--gg-primary);
    color:  var(--gg-white);
}
.sign-in-btn:disabled {
    background:  var(--gg-primary);
    color: var(--gg-white);
    opacity: .8;
}

.public-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.gg-form-control{
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    background-clip:padding-box;
    background-color: var(--gg-white);
    border:1px solid var(--bs-gray-300);
    border-radius:.625rem;
    box-shadow:0 0 transparent;
    color: black;
    display:block;
    font-size:.95rem;
    font-weight:400;
    line-height:1.5;
    padding:.625rem .75rem;
    transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width:100%
}

.invalid-gg-input{
    border:1px solid red !important;
}
.invalid-gg-input:focus{
    border:1px solid  var(--gg-primary);
    -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.3) inset;
    box-shadow:0 1px 2px rgba(0, 0, 0, 0.3) inset;
    outline: none;
}
.invalid-gg-input::after{
    border:1px solid red !important;
}

.invalid-gg-input:active{
    border:1px solid red !important;
}


.bg-gg-white{
    background-color:  var(--gg-white);
}

.ctcb:hover{
    cursor: copy;
}

.mp{
    cursor: pointer;
}

.cp{
    cursor: copy;
}

.prod-image-parent-modal img{
    height: 200px;
    border-radius: 5px;
}

.coupons-clients-list{
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.coupons-clients-list li:hover{
    border-color: #006CB5;
    border-width: 1px;
    border-radius: 5px;
}

.coupons-clients-list .icon:hover{
    color: red;
    background-color: red;
}

.tokens-clients-list{
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
}


.text-uppercase {text-transform: uppercase;}
.text-lowercase {text-transform: lowercase;}
.text-capitalize {text-transform: capitalize;}


.ribbon-parent{
    overflow: hidden;
    position: relative;
}

.ribbon {
    margin: 0;
    padding: 0;
    background: var(--gg-blue);
    opacity: 1 !important;
    z-index: 1;
    color:white;
    padding: .5em 0;
    position: absolute;
    top:0;
    right:0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
}

.ribbon-m {
    margin: 0;
    padding: 0;
    background: var(--gg-blue);
    opacity: 1 !important;
    z-index: 1;
    color: white;
    padding: 0.5em 0;
    position: absolute;
    top: -61px;
    right: -56px;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
}

.ribbon-order {
    margin: 0;
    padding: 0;
    background: var(--gg-blue);
    opacity: 1 !important;
    z-index: 1;
    color:white;
    padding: .5em 0;
    position: absolute;
    top:0;
    right:0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
}

.ribbon:before,
.ribbon:after {
    content: '';
    position: absolute;
    top:0;
    margin: 0 -1px; /* tweak */
    width: 100%;
    height: 100%;
    background: var(--gg-blue);
}
.ribbon:before {
    right:100%;
}

.ribbon:after {
    left:100%;
}

.data-hdg {
    padding-top:50px;
}

/** Chat bot - Llama*/

#chat-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

#myPopup {
    z-index: 999 !important;
}

#myopenButton {
    background-color:#006CB5 !important;
    background-image: url('https://go-gas.co.za/wp-content/uploads/2019/12/Gas-Icon_1White.svg') !important;
    z-index: 999 !important;
}

.opacity-95{opacity: 0.95;}
.opacity-95{opacity: 0.90;}
.opacity-85{opacity: 0.85;}
.opacity-85{opacity: 0.80;}
.opacity-75{opacity: 0.75;}
.opacity-75{opacity: 0.70;}
.opacity-65{opacity: 0.65;}
.opacity-65{opacity: 0.60;}
.opacity-55{opacity: 0.55;}
.opacity-55{opacity: 0.50;}
.opacity-45{opacity: 0.45;}
.opacity-45{opacity: 0.40;}
.opacity-35{opacity: 0.35;}
.opacity-35{opacity: 0.30;}
.opacity-25{opacity: 0.25;}
.opacity-25{opacity: 0.20;}
.opacity-15{opacity: 0.15;}
.opacity-15{opacity: 0.10;}

.client-order-comp-on-modal{
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
}

.btn-inter-internal{
    --bs-btn-color:#fff;
    --bs-btn-bg:var(--gg-blue);
    --bs-btn-border-color:var(--gg-blue);
    --bs-btn-hover-color:#fff;
    --bs-btn-hover-bg:var(--gg-blue);
    --bs-btn-hover-border-color: var(--gg-blue);
    --bs-btn-focus-shadow-rgb:38,196,207;
    /* --bs-btn-active-color:#fff;
    --bs-btn-active-bg:#00959f;
    --bs-btn-active-border-color:#008c95;
    --bs-btn-active-shadow:0 0 transparent;
    --bs-btn-disabled-color:#fff;
    --bs-btn-disabled-bg:#00bac7; */
    --bs-btn-disabled-border-color:#00bac7;
    padding-left: .8em;
    padding-right: .8em;
}

.btn-inter-internal:disabled{
    background-color: var(--gg-blue);
    border-color:var(--gg-blue);
    box-shadow:none;
    color: white;
    opacity: 1;
    pointer-events:none
}

.dash-head-row{
    display: flex;
    flex-direction: row;
    Justify-content: space-around;
}

.mid-link-header{
    margin-left: 40px;
}

.aff-code{
    text-align: right;
    float: right;
}

.btn-dashed {
    border: 3px dashed black;
    width: 20rem;
}

.btn-dashed:hover{
    border: 3px dashed black;
    background: #ffffff;
}

.widget-dropdown {
    align-content: center;
    padding-left: 15px;
}

#dashBtn{
    color: black;
}

#dashBtn:hover{
    color:#006CB5 !important;
}

#dashBtn:focus{
    color:#006CB5 !important;
}

.analytics-dash{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.add-info-popup{
    position: relative;
    max-width: 250px;
    background-color: var(--gg-blue);
    border-radius: 20px;
    /* padding: 15px; */
    color: white;
    z-index: 99 !important;
}

.add-info-popup-mobile{
    position: relative;
    max-width: 250px;
    background-color: var(--gg-blue);
    /* border-radius: 20px; */
    /* padding: 15px; */
    color: white;
    z-index: 99 !important;
}

.social-icon-p{
    width: 44px !important;
}

.promo-card .social-icon{
    margin-left: 5px;
}

.social-icon{
    height: 35px;
    width: 35px;
    /* margin-bottom: 1em; */
    padding: 10px;
    border-radius: 20px;
}

.icon-whatsapp{
    background-color: #65D072;
    color: white;
}

.icon-facebook{
    background-color: #425893;
    color: white;
}

.icon-elon{
    background-color: #4D9FEB;
    color: white;
}

.icon-insta{
    background-color: #EB864D;
    color: white;
}

.icon-mail{
    background-color: #888888;
    color: white;
}

.icon-sms{
    background-color: #006CB5;
    color: white;
}

.icon-txt{
    color: black;
    font-size: 10px;
    font-weight: 400;
    width: 44px;
}

.icon-txt-sm {
    display: inline-block;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    color: black;
}

.click-text{
    font-size: 16px;
}

.product-list{
    list-style-type: none;
}

.table .form-control {
    height: 30px;
    padding: 10px 10px 10px 10px;
    font-size: 12px;
}

.nav-tabs {
    border-bottom: none !important;
}

.card-page{
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.reports-card{
    border: none;
    background: #efefef;
    margin-left:0.25rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    min-width: 250px;
    box-shadow: none;
    cursor: pointer;
}

.pagination {
    display: flex;
    flex-direction: row;
}

.pagination b {
    margin-bottom: 2rem;
}

.pagination .btn {
    background: #006CB5;
    height: 36px;
    width: 36px;
    color: white;
    border: none;
}

.pagination .btn:hover {
    background: #FFDF3C;
    color: #006CB5;
}

.page-number{
    padding-top:0%;
    margin-top: -0.5rem;
}

.load-btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
    background: #006CB5;
    border: none;
}

.load-btn:hover{
    color: #006CB5;
    background: #FFDF3C;
}

.tab-navs {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

#tab-dropdown{
    background: white !important;
    border: none;
    color: #006CB5;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
}

#tab-dropdown::after{
    display: none;
}

.checkbox-dropdown {
    position: absolute;
    display: flex;
}

.dropdown-button {
    background-color: white;
    color: #006CB5;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: flex;
    background: #FEDF3D;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    width: 70vw;
    height: 90vh;
    top: 100%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-top: none;
    z-index: 99 !important;
}

.dropdown-content input {
    margin-right: 10px;
    width: 16px;
    height: 16px;
}

.dropdown-content input[type="checkbox"]:checked{
    background-color: #006CB5;
    color: white;
}

.option-item{
    background: #efefef;
    margin: 3px;
    width: 150px;
    border-radius: 5px;
    padding: 3px;
}

.dropdown-content label {
    font-size: 16px;
    color: #006CB5;
    font-weight: 400;
}

label {
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
}

  /* .chart-card{
    width: 550px;
    height: 450px;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
  } */

.select-none{
    font-size: 14px;
    color: #000;
}

.resp-td-col{
    min-width: 10rem;
}

.column-search {
    min-height: 75px;
    display: flex;
    align-items: center;
}

.column-search .form-control {
    border-radius: 0 !important;
    min-width: 10rem;
    border: none;
}

.table select {
    border: 0;
    width: 100%;
    height: 1.75rem;
}

.table input[type="text"] {
    border: 0;
}

/* .grid-container {
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: top;
    justify-content:center;
    gap: 10px;
    padding: 1rem;
    margin-left: 0 !important;
    --bs-gutter-x: 0;
  } */

/* .grid-item{
    margin: 0.15rem;
    width: auto;
    height: auto;
  } */

.grid-item-content {
    background: #efefef;
    text-align: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(75px, auto);
    grid-column-gap: 0.25em;
    grid-row-gap: 0.25em;
    padding: 1rem;
}

.grid-item {
    grid-column: span 1;
    grid-row: span 2;
}

.number-card {
    grid-row: span 1;
}

.chart-card {
    grid-row: span 3;
    grid-column: span 2;
}

#add-cylinder-return{
    font-size: 32px;
    color: var(--gg-secondary);
    cursor: pointer;
}

#remove-cylinder-btn{
    font-size: 32px;
    color: red;
    cursor: pointer;
}

#save-cylinder-btn{
    font-size: 32px;
    color: green;
    cursor:pointer;
}

.credit-return-nav{
    cursor: pointer;
}

.background-danger {
    background: #f8d7da;
}

.background-success {
    background: #d4edda;
}


.fs-5_5 {
font-size: 0.8rem !important;
}

/* Width */
::-webkit-scrollbar {
    width: 7px;
}

  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal-toggle-button {
    position: fixed; 
    bottom: 15px; 
    right: 100px; 
    z-index: 1050; 
}

.modal-call-me label{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #006CB5;
    font-weight: 400;
}

.modal-call-body .modal-title {
    font-size: 32px !important;
    font-weight: 700;
}

.modal-call-me .form-control {
    border: 1px solid black;
    border-radius: 0px;
    font-size: 16px;
}